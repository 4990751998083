import React from "react"
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaGithub,
  FaMedium,
  FaAngellist,
  FaDribbble,
  FaStrava,
  FaSpotify
} from "react-icons/fa"
import { Helmet } from "react-helmet"
import team from "../images/team.jpg"
// import Spotify from "react-spotify-embed"

export default function Home() {
  return (
    <div className="wrapper">
      <Helmet>
        <meta charSet="utf-8" />

        <title>
          Praveena Sarathchandra | Entrepreneur &amp; Tech Consultant from Sri
          Lanka
        </title>
        <meta
          name="description"
          content="Founder | Building remote teams with top Sri Lankan talent for startups/scale-ups worldwide 🌎 | Web, Mobile & Cloud development partner 💯 | Launching bootstrapped startup ventures 🚀 | Open for partnerships 🤝"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
<link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet"/>

<script async src="https://www.googletagmanager.com/gtag/js?id=G-28XLSFDFR5"></script>
<script>
  {`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-28XLSFDFR5');
  `}
</script>
      </Helmet>
      <div className="container">
        <div className="header">
          <h1 className="name">praveena sarathchandra</h1>
          <h3 className="title">Entrepreneur &amp; Tech Consultant</h3>
        </div>

        <div className="description">
          <p>
            I'm the founder of{" "}
            <a href="http://smashtaps.com" target="_blank">
              SmashTaps
            </a>{" "}
            and{" "}
            <a href="https://hellomolly.io" target="_blank">
              Molly,
            </a>{" "}
            and a co-founder of <a href="http://kepler.app">Kepler</a>.
            Previously, I led a team at{" "}
            <a href="https://www.pypestream.com/" target="_blank">
              Pypestream Inc.
            </a>
            , New York and worked as an engineer at{" "}
            <a href="http://wso2.com" target="_blank">WSO2</a>, Colombo. I'm a generalist and a
            jack-of-<i>few</i>-trades.
          </p>
          <p>
            My{" "}
            <a href="https://en.wikipedia.org/wiki/Ikigai" target="_blank">
              Ikigai
            </a>{" "}
            is to craft amazing digital products by combining design, creativity
            and technology to <a href="https://youtu.be/4tLvzyb3_Uc?t=110" target="_blank">make the world a better place!</a>
          </p>
          <p>
            When I'm not wired in, you can find me geeking out over Swiss design, stoicism, music, cinema, architecture, art and photography. I also find joy in
             traveling, working out, running and savoring every drop of coffee from a cozy cafe.{" "}

              INTJ

            . Let's connect!
          </p>

          <div className="social-media">
            <a href="http://www.facebook.com/praveenaj" target="_blank">
              <FaFacebook size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://www.twitter.com/praveenasara" target="_blank">
              <FaTwitter size={"calc(2vw + 16px)"} />
            </a>
            <a href="http://www.linkedin.com/in/praveenasarathchandra" target="_blank">
              <FaLinkedin size={"calc(2vw + 16px)"} />
            </a>
            <a href="http://instagram.com/praveenaj" target="_blank">
              <FaInstagram size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://www.strava.com/athletes/praveena_sarathchandra" target="_blank">
              <FaStrava size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://open.spotify.com/playlist/5jYYhxvWXRi0xwou9ilyPD?si=fa175babe21b4b4f" target="_blank">
              <FaSpotify size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://github.com/praveenaj" target="_blank">
              <FaGithub size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://medium.com/@praveenasara" target="_blank">
              <FaMedium size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://angel.co/praveenasara" target="_blank">
              <FaAngellist size={"calc(2vw + 16px)"} />
            </a>
            <a href="https://dribbble.com/praveenaj" target="_blank">
              <FaDribbble size={"calc(2vw + 16px)"} />
            </a>
          </div>
        </div>
      </div>
      <img
        className="team"
        src={team}
      />
    </div>
  )
}
